import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const ProtectedPage = () => {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);

      if (!session) {
        navigate('/login');  // Redirect to login if not authenticated
      }
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (!session) {
        navigate('/login');  // Redirect to login if not authenticated
      }
    });

    // Cleanup listener on component unmount
    return () => {
      authListener.subscription?.unsubscribe();  // Safely remove the listener if it exists
    };
  }, [navigate]);

  if (!session) return <p>Loading...</p>;

  return <div>Welcome to the protected page!</div>;
};

export default ProtectedPage;