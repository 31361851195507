import React from "react";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/solid"; // Importing profile icon

const Header = () => {
  return (
    <header className="bg-white shadow-md py-2 sticky top-0 z-50">
      {/* Sticky Navbar with shadow */}
      <div className="container mx-auto px-4 flex flex-wrap justify-between items-center">
        {/* Left: MakerTerminal Icon */}
        <div>
          <Link to="/" className="flex items-center">
            <img
              src={require("../images/mticon2.png")}
              alt="MakerTerminal Icon"
              className="h-8 w-8"
            />
          </Link>
        </div>

        {/* Center: Navigation Links */}
        <div className="flex flex-wrap space-x-6 text-center mt-2 sm:mt-0">
          <Link
            to="/vcs"
            className="text-m text-gray-700 hover:text-blue-600 hover:underline"
          >
            Investors
          </Link>
          <Link
            to="/competitions"
            className="text-m text-gray-700 hover:text-blue-600 hover:underline"
          >
            Full Database
          </Link>
          <Link
            to="/grants"
            className="text-m text-gray-700 hover:text-blue-600 hover:underline"
          >
            Coming Soon
          </Link>
        </div>

        {/* Right: Profile Icon */}
        <div className="mt-2 sm:mt-0">
          <Link to="/login">
            <UserIcon className="h-6 w-6 text-blue-600 hover:text-blue-700" />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;

