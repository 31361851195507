import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AuthComponent from './components/AuthComponent';
import ProtectedPage from './components/ProtectedPage';
import LandingPage from './components/LandingPage';
import './App.css';
import VCsFundsPage from './pages/VCsFundsPage';
import CompetitionsPage from './pages/CompetitionsPage';
import GrantsPage from './pages/GrantsPage';
import AcceleratorsPage from './pages/AcceleratorsPage';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Account from './components/Account'

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/vcs" element={
          <PrivateRoute>
            <VCsFundsPage />
          </PrivateRoute>
        } />
        <Route path="/competitions" element={
          <PrivateRoute>
            <CompetitionsPage />
          </PrivateRoute>
        } />
        <Route path="/grants" element={<GrantsPage />} />
        <Route path="/accelerators" element={<AcceleratorsPage />} />
        <Route path="/login" element={<AuthComponent />} />
        <Route path="/account" element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        } />
        <Route path="/protected" element={<ProtectedPage />} />
      </Routes>
    </Router>
  );
}

export default App;