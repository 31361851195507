import React from 'react';

const FullDatabase = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-600 to-blue-500 flex flex-col items-center p-8">
      {/* Title */}
      <h1 className="text-4xl font-bold text-white mb-6">Full Database</h1>

      {/* Paragraph */}
      <p className="text-lg text-white text-center max-w-3xl mb-12">
        {/* Replace this content with your actual paragraph */}
        This is the Full Database page. Here, you can explore our comprehensive database while we work on the finishing touches in making filtering and searching through the data possible for our users!
      </p>

      {/* Airtable Embed */}
      <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4">
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/appwCCThI6zUa6WNQ/shrPd18KGaQqJRUeg"
          frameBorder="0"
          onMouseWheel=""
          width="100%"
          height="533"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
          title="Full Database Airtable"
        ></iframe>
      </div>
    </div>
  );
};

export default FullDatabase;
