import React, { useState } from "react";
import { Link } from "react-router-dom";
import aboutImage from "../images/aboutimage.jpg";
import footerImage from "../images/mtlogocloseup3.png";
import { PopupModal } from "react-calendly";

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white flex items-center justify-center h-screen px-4">
        <div className="max-w-4xl text-center">
          <h1 className="text-5xl font-extrabold text-white animate-pulse mb-4">
            Welcome to MakerTerminal.com
          </h1>
          <p className="text-xl mb-8 font-light">
            The ultimate free platform for exploring VCs, Competitions, Grants,
            Accelerators, and more for young founders.
          </p>
          <div className="flex justify-center space-x-4">
            <Link to="/vcs">
              <button className="bg-white text-purple-600 py-3 px-6 rounded-lg font-semibold shadow-lg hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105">
                Let's Go!
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="w-full py-16 bg-gray-100">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Explore Our Features</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* VCs Section */}
            <Link
              to="/vcs"
              className="bg-white text-purple-600 py-6 px-8 rounded-lg shadow-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex flex-col justify-between h-full"
            >
              <h3 className="text-2xl font-semibold mb-2">VCs/Funds</h3>
              <p className="font-light">
                Explore venture capitalists and funding options.
              </p>
            </Link>

            {/* Competitions Section */}
            <Link
              to="/grants"
              className="bg-white text-purple-600 py-6 px-8 rounded-lg shadow-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex flex-col justify-between h-full"
            >
              <h3 className="text-2xl font-semibold mb-2">Competitions</h3>
              <p className="font-light">
                Find and participate in industry competitions.
              </p>
            </Link>

            {/* Grants Section */}
            <Link
              to="/grants"
              className="bg-white text-purple-600 py-6 px-8 rounded-lg shadow-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex flex-col justify-between h-full"
            >
              <h3 className="text-2xl font-semibold mb-2">Grants</h3>
              <p className="font-light">Discover grants that suit your needs.</p>
            </Link>

            {/* Accelerators Section */}
            <Link
              to="/grants"
              className="bg-white text-purple-600 py-6 px-8 rounded-lg shadow-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex flex-col justify-between h-full"
            >
              <h3 className="text-2xl font-semibold mb-2">Accelerators</h3>
              <p className="font-light">
                Find accelerators, incubators, and fellowships.
              </p>
            </Link>
          </div>
        </div>
      </section>


      {/* About Section */}
      <section className="w-full py-16 bg-gradient-to-r from-blue-500 to-purple-600 text-white text-center">
        <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center px-4">
          {/* Text Content */}
          <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
            <h2 className="text-3xl font-bold mb-4">About MakerTerminal</h2>
            <p className="text-lg font-light mb-4">
              MakerTerminal aims to reduce information asymmetry among
              early-stage founders by providing a robust and free database of
              funding opportunities. Soon, users will have free access to four
              comprehensive databases: Investors (VCs, Funds, Angels),
              Accelerators, Grants, and Competitions.
            </p>
            <p className="text-lg font-light">
              We are working hard on new features and on releasing our 4 full
              databases!
            </p>
          </div>
          {/* Image Placeholder */}
          <div className="lg:w-1/2">
            <img
              src={aboutImage}
              alt="About MakerTerminal"
              className="rounded-lg shadow-md w-full object-cover"
            />
          </div>
        </div>
      </section>

      {/* LinkedIn Section */}
      <section className="w-full py-16 bg-gray-100 flex flex-col items-center">
        <h2 className="text-3xl font-bold mb-8">Meet The Team</h2>
        <div className="w-full max-w-6xl flex flex-col lg:flex-row items-start">
          {/* Left Paragraph */}
          <div className="w-full lg:w-1/2 p-6">
            <p className="text-lg text-gray-700">
              MakerTerminal is a non-profit platform started by two 18-year-old secondary school students, Evan and Julian, who are passionate about startups and venture capital. We wanted to create a free-to-use resource for young founders to find the right resource or opportunity at the right time.
            </p>
          </div>

          {/* Divider */}
          <div className="hidden lg:block w-px bg-gray-300 mx-6"></div>

          {/* Right Paragraph */}
          <div className="w-full lg:w-1/2 p-6">
            <p className="text-lg text-gray-700">
              We are working as hard as we can to bring more data and more functionality. Soon we will be releasing all 4 of our comprehensive databases with new querying functionality! 
              <br />
              <Link to="/grants" className="text-blue-600 hover:underline">
                Learn more about what's coming soon.
              </Link>
            </p>
          </div>
        </div>
        <div className="w-full max-w-6xl text-center">
          <p className="text-lg text-gray-700 mb-6">
            Feel free to chat to us on LinkedIn or book a call with us!
          </p>
          <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-4">
            {/* Link to First LinkedIn Profile */}
            <a
              href="https://www.linkedin.com/in/evan-wynne-sps/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline text-lg"
            >
              Evan
            </a>
            <span className="text-gray-500 text-lg">|</span> {/* Placeholder Text Between Links */}
            {/* Link to Second LinkedIn Profile */}
            <a
              href="https://www.linkedin.com/in/julianlew/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline text-lg"
            >
              Julian
            </a>
            <span className="text-gray-500 text-lg">|</span> {/* Placeholder Text Between Links */}
            <div>
              <button
                onClick={openModal}
                className="text-blue-600 hover:underline text-lg"
              >
                Schedule a Call
              </button>
              {/* Calendly Modal */}
              {isOpen && (
                <PopupModal
                  url="https://calendly.com/julianlewandowski/makerterminal-demo"
                  onClose={closeModal} // Ensure the modal closes when "x" is clicked
                  rootElement={document.getElementById("root")}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Email Sign-Up Section */}
      <section className="w-full py-16 bg-gray-100 flex flex-col items-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Join Our Community</h2>
        <p className="text-lg text-gray-700 text-center mb-8 max-w-3xl">
          Sign up to stay updated on releases, new features, and new data!
        </p>
        <div className="bg-white shadow-lg rounded-lg p-4 w-full max-w-md">
          <iframe
            className="w-full h-96"
            src="https://airtable.com/embed/appq1bjQL4PGHtFGl/pagSGfWXurlwDYHOL/form"
            frameBorder="0"
            style={{
              background: 'rgba(255, 255, 255, 0.8)',
              border: '1px solid #ccc',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            title="Email Sign-Up Form"
          ></iframe>
        </div>
      </section>

      {/* Footer */}
      <footer className="w-full bg-purple-100 text-gray-800 py-6 flex flex-col md:flex-row justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <div className="ml-[10%] w-64 h-16 flex items-center justify-center">
            <img src={footerImage} alt="MakerTerminal Logo" />
          </div>
        </div>
        {/* Copyright */}
        <div className="mr-[5%] text-center md:text-right mt-4 md:mt-0">
          &copy; 2024 MakerTerminal. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
